import * as Popover from '@radix-ui/react-popover';
import React, { useEffect, useRef, useState } from 'react';

import { IconSpinner } from 'components';
import { Brand, useGetBrands } from 'hooks/api/listings/useGetBrands';

interface BrandComboboxProps {
  value: string;
  onBrandSelect: (brand: Brand) => void;
  disabled?: boolean;
  onChange: (value: string) => void;
}

const BrandCombobox: React.FC<BrandComboboxProps> = ({
  disabled,
  onBrandSelect,
  value = '',
  onChange,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { data: brands = [], isLoading, isError } = useGetBrands();
  const matchingBrands = brands.filter(brand =>
    brand.name.toLowerCase().includes(value.toLowerCase())
  );
  const filteredBrands = matchingBrands.slice(0, 20);
  const noResults = filteredBrands.length === 0 && value.length > 0;
  const remainingBrands = matchingBrands.length - filteredBrands.length;

  useEffect(() => {
    const activeButton = popoverRef.current?.querySelector(
      `[data-active="true"]`
    ) as HTMLButtonElement;
    activeButton?.scrollIntoView({ block: 'nearest' });
  }, [activeIndex]);

  useEffect(() => {
    setActiveIndex(0);
    popoverRef.current?.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filteredBrands)]);

  const handleItemClick = (brand: Brand) => {
    onBrandSelect(brand);
    setIsPopoverOpen(false);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveIndex(prevIndex => (prevIndex + 1) % filteredBrands.length);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveIndex(prevIndex => (prevIndex - 1 + filteredBrands.length) % filteredBrands.length);
    } else if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      if (filteredBrands.length > 0) {
        handleItemClick(filteredBrands[activeIndex]);
      }
    } else if (e.key === 'Escape') {
      setIsPopoverOpen(false);
      e.preventDefault();
    }
  };

  return (
    <div className="relative">
      <Popover.Root open={isPopoverOpen && value.length > 0 && !disabled}>
        <Popover.Anchor asChild>
          <input
            type="text"
            value={value}
            ref={inputRef}
            placeholder="Search for a brand"
            onChange={e => {
              onChange(e.target.value);
              setIsPopoverOpen(true);
            }}
            onKeyDown={handleInputKeyDown}
            onBlur={e => {
              if (
                e.relatedTarget &&
                (e.relatedTarget as HTMLElement).getAttribute('data-popover-button')
              ) {
                return;
              }

              if (popoverRef.current?.contains(e.relatedTarget as Node)) {
                return;
              }

              setIsPopoverOpen(false);
            }}
            disabled={disabled}
          />
        </Popover.Anchor>
        <Popover.Portal>
          <Popover.Content
            ref={popoverRef}
            onOpenAutoFocus={e => e.preventDefault()}
            className="z-[1000] overflow-y-auto max-h-[min(var(--radix-popover-content-available-height),50vh)] bg-white border border-black rounded-md"
            collisionPadding={32}
            side="bottom"
            sideOffset={8}
            style={{ width: inputRef.current?.offsetWidth }}
          >
            <div className="w-full p-2">
              {isLoading && (
                <div className="grid place-items-center">
                  <IconSpinner />
                </div>
              )}
              {isError && (
                <div className="text-red-500">
                  <div>Couldn't get brands</div>
                </div>
              )}
              {!isLoading && noResults && !isError && (
                <div>No matching brands found. Please try a different search.</div>
              )}
              {!isLoading &&
                filteredBrands.map((brand, index) => (
                  <button
                    key={brand.id}
                    data-active={index === activeIndex}
                    data-popover-button
                    onClick={() => handleItemClick(brand)}
                    type="button"
                    className="cursor-pointer relative block w-full p-2 text-sm text-left bg-transparent border-none rounded-sm hover:bg-gray-100 data-[active=true]:bg-gray-100"
                  >
                    {brand.name}
                  </button>
                ))}
              {remainingBrands > 0 && <div className="pt-2">And {remainingBrands} more...</div>}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default BrandCombobox;
