import { PiChatsCircleThin } from 'react-icons/pi';

import { Posting } from './PostingsPage.hooks';
import { PostingUser } from './PostingsUser';
import { useGetBrands } from 'hooks/api/listings/useGetBrands';
import { cn } from 'util/cn';
import { ImageType } from 'util/images';
import { toImageObject } from 'util/images';

interface PostingItemProps {
  className?: string;
  posting: Posting;
  onClickPosting: (postingId: number) => void;
  compact?: boolean;
}

export const PostingItem = ({
  className,
  posting,
  onClickPosting,
  compact = false,
}: PostingItemProps) => {
  const { data: brands } = useGetBrands();
  const brandName = brands?.find(b => b.id === posting.brandId)?.name;
  const sizeText = posting.size && `Size: ${posting.size}`;
  const brandAndSizeText =
    brandName || sizeText ? `(${[brandName, sizeText].filter(Boolean).join(', ')})` : '';
  const descriptionElement = (
    <h3 className="line-clamp-2 px-2 font-medium">
      <span className="bg-custom-green px-.25">#lookingfor</span>
      <span> {posting.description}</span>
      {brandAndSizeText && <span className="text-type-subtle"> {brandAndSizeText}</span>}
    </h3>
  );

  const image = toImageObject(posting.imageId, ImageType.LookingFor);

  return (
    <div
      className={cn(
        'h-full grid grid-rows-[auto_auto_1fr_auto] cursor-pointer first:border-t-0 border-t overflow-hidden',
        className
      )}
      onClick={() => onClickPosting(posting.id)}
    >
      <PostingUser user={posting.user} date={posting.createdAt} />
      {descriptionElement}
      <div className="px-2 grid grid-rows-[auto_1fr] gap-2 overflow-hidden mt-2">
        <div className="overflow-hidden">
          <img
            srcSet={image.srcset}
            sizes=""
            onError={image.onError}
            alt={posting.description}
            className="rounded-xl mx-auto object-contain h-full w-full max-h-[300px]"
          />
        </div>
      </div>
      <div className="flex justify-between items-center mt-1 ">
        <button className="p-2 pr-1 flex items-center text-left gap-1 font-medium">
          <PiChatsCircleThin size={28} />
          <span className="text-nowrap">Replies</span>
        </button>
        <span className="text-type-subtle pr-2 py-2 text-right">
          {posting.comments?.length === 0
            ? 'No replies'
            : posting.comments?.length === 1
            ? 'See reply'
            : `${posting.comments?.length} replies`}
        </span>
      </div>
    </div>
  );
};
