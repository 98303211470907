import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import { CreateCommentParams, CreatePostingParams, GetPostingsParams } from './PostingTypes';
import { getPresignedUrl } from 'hooks/api/misc/getPresignedUrl';
import { useGeolocation } from 'hooks/useGeolocation';
import { useQueryParams } from 'hooks/useQueryParams';
import { mapListingResponseToListing } from 'models/mappers/ListingMapper';
import { del, get, post } from 'util/httpsClient';
import { uploadFile } from 'util/httpUtils';
import { processImage } from 'util/imageProcessing';
import { ImageType } from 'util/images';
import { areAllImagesLoaded } from 'util/images/imagePolling';

const userSchema = z.object({
  id: z.string(),
  handle: z.string(),
  fullName: z.string(),
  imageUrl: z.string().nullable(),
});

const commentSchema = z.object({
  id: z.number(),
  content: z.string().nullish(),
  postingId: z.number(),
  userId: z.string(),
  listing: z.any().nullish(),
  imageIds: z.array(z.string()),
  createdAt: z.string(),
  listingId: z.string().nullable(),
  user: userSchema,
});

const postingSchema = z.object({
  id: z.number(),
  brandId: z.coerce.number().nullable(),
  size: z.string(),
  description: z.string(),
  userId: z.string(),
  isDeleted: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
  imageId: z.string(),
  user: userSchema,
  comments: z.array(commentSchema),
});

const dataSchema = z.object({
  postings: z.array(postingSchema),
  total: z.number(),
  page: z.number(),
  pageSize: z.number(),
});

export type Posting = z.infer<typeof postingSchema>;
export type PostingData = z.infer<typeof dataSchema>;
export type PostingUser = z.infer<typeof userSchema>;

const fetchPostings = async (params: GetPostingsParams, userGeolocation?: string) => {
  const queryParams = new URLSearchParams();

  Object.entries({ ...params, country: userGeolocation }).forEach(([key, value]) => {
    if (value !== undefined) {
      queryParams.append(key, value.toString());
    }
  });

  const queryString = queryParams.toString();
  const path = `/postings${queryString ? `?${queryString}` : ''}` as const;

  const response = dataSchema.parse(await get({ path }));

  return {
    postings: response.postings,
    currentPage: response.page,
    totalPages: Math.ceil(response.total / response.pageSize),
  };
};

export const DEFAULT_POSTINGS_PAGE_SIZE = 30;

export const useGetPostings = (
  params: GetPostingsParams = { pageSize: DEFAULT_POSTINGS_PAGE_SIZE }
) => {
  const { userGeolocation } = useGeolocation();

  return useInfiniteQuery({
    queryKey: ['postings', params],
    queryFn: ({ pageParam = 1 }) => fetchPostings({ ...params, page: pageParam }, userGeolocation),
    getNextPageParam: lastPage => {
      if (lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1;
      }
      return undefined;
    },
    meta: {
      errorMessage: 'Failed to fetch postings',
    },
  });
};

const fetchPosting = async (id: number) => {
  const path = `/postings/${id}` as const;
  const response = postingSchema.parse(await get({ path }));

  return {
    ...response,
    comments: response.comments.map(comment => ({
      ...comment,
      listing: comment.listing ? mapListingResponseToListing(comment.listing) : undefined,
    })),
  };
};

export const useGetPosting = (id: number | undefined) => {
  return useQuery({
    queryKey: ['posting', id],
    queryFn: () => {
      if (id === undefined) {
        return undefined;
      }
      return fetchPosting(id);
    },
    meta: {
      errorMessage: 'Failed to fetch posting',
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: id !== undefined,
  });
};

const createPosting = async ({ image, ...postingData }: CreatePostingParams) => {
  let imageId: string | undefined;
  if (image) {
    const processedImage = await processImage(image);
    const presignedUrlResponse = await getPresignedUrl([
      {
        fileName: processedImage.processedFile.name.replace(/(\.[^.]+)$/, '_removebg$1'),
        imageType: ImageType.LookingFor,
        fileType: processedImage.processedFile.type,
      },
    ]);

    await uploadFile(presignedUrlResponse[0].presignedUrl, processedImage.processedFile);
    imageId = processedImage.imageId;
  }

  const path = '/postings' as const;
  const response = postingSchema.parse(
    await post({
      path,
      body: {
        ...postingData,
        imageId,
      },
    })
  );

  await areAllImagesLoaded([response.imageId], { type: ImageType.LookingFor });

  return {
    ...response,
    comments: response.comments.map(comment => ({
      ...comment,
      listing: comment.listing ? mapListingResponseToListing(comment.listing) : undefined,
    })),
  };
};

export const useCreatePosting = () => {
  const queryClient = useQueryClient();
  return useMutation(createPosting, {
    onSuccess: () => {
      queryClient.invalidateQueries(['postings']);
    },
    meta: {
      errorMessage: 'Failed to create posting',
    },
  });
};

const createComment = async ({ postingId, images, ...commentData }: CreateCommentParams) => {
  let imageIds: string[] = [];
  if (images.length > 0) {
    imageIds = await Promise.all(
      images.map(async image => {
        const processed = await processImage(image);
        const presignedUrl = await getPresignedUrl([
          {
            fileName: processed.processedFile.name,
            imageType: ImageType.LookingFor,
            fileType: processed.processedFile.type,
          },
        ]);
        await uploadFile(presignedUrl[0].presignedUrl, processed.processedFile);
        return processed.imageId;
      })
    );
  }

  const path = `/postings/${postingId}/comments` as const;
  const response = commentSchema.parse(await post({ path, body: { ...commentData, imageIds } }));

  return {
    ...response,
    listing: response.listing ? mapListingResponseToListing(response.listing) : undefined,
  };
};

export const useCreateComment = () => {
  const queryClient = useQueryClient();
  return useMutation(createComment, {
    onSuccess: () => {
      queryClient.invalidateQueries(['postings']);
      queryClient.invalidateQueries(['posting']);
    },
    meta: {
      errorMessage: 'Failed to create comment',
    },
  });
};

const archivePosting = async (id: number) => {
  const path = `/postings/${id}` as const;
  return del({ path });
};

export const useArchivePosting = () => {
  const queryClient = useQueryClient();
  return useMutation(archivePosting, {
    onSuccess: (_, id) => {
      queryClient.invalidateQueries(['postings']);
      queryClient.invalidateQueries(['posting', id]);
    },
    meta: {
      errorMessage: 'Failed to archive posting',
    },
  });
};

const deleteComment = async (id: number) => {
  const path = `/postings/comments/${id}` as const;
  return del({ path });
};

export const useDeleteComment = () => {
  return useMutation(deleteComment, {
    meta: {
      errorMessage: 'Failed to delete comment',
    },
  });
};

export const postingsQueryParamSchema = z.object({
  postingId: z.number().optional(),
  layout: z.enum(['grid', 'list']).optional(),
});

export function usePostingsQueryParams() {
  return useQueryParams({ layout: 'grid', postingId: undefined }, postingsQueryParamSchema);
}
