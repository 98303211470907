import { useMutation } from '@tanstack/react-query';

import { post } from 'util/httpsClient';
import { ImageType } from 'util/images';

export const getPresignedUrl = async (
  files: {
    fileName: string;
    imageType: ImageType;
    fileType: string;
  }[]
): Promise<{ presignedUrl: string; imageId: string }[]> => {
  const path = '/presigned-urls' as const;
  return post({ path, body: { files } });
};

export const usePresignedUrl = () => {
  return useMutation(getPresignedUrl, {
    meta: {
      errorMessage: 'Failed to get presigned URL',
    },
  });
};
