import { ImageSize } from './imageSize';
import { getImageUrl, ImageType } from 'util/images';

// Poll every 3 seconds, for up to 1 minute (20 checks).
const POLL_INTERVAL = 3000;
const MAX_POLLS = 20;

interface Options {
  variantKey?: string;
  type?: ImageType;
}

/**
 * Sleeps for the specified number of milliseconds.
 * @param ms Number of milliseconds to sleep.
 */
const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Checks if all images have their variants ready by performing HEAD requests.
 * @param imageIds Array of image IDs to check.
 * @param variantKey Key of the variant to check in each image.
 * @returns Promise that resolves to true if all images are ready, false otherwise.
 */
const checkAllImagesLoaded = async (
  imageIds: string[],
  variantKey: string,
  type?: ImageType
): Promise<boolean> => {
  const checks = imageIds.map(async imageId => {
    const variantUrl = getImageUrl(imageId, variantKey, type);
    if (!variantUrl) {
      return false;
    }

    try {
      const response = await fetch(variantUrl, { method: 'HEAD' });
      return response.ok;
    } catch (error) {
      console.error(`Error fetching ${variantUrl}:`, error);
      return false;
    }
  });

  // Wait for all checks to complete and return true if all are loaded.
  const results = await Promise.all(checks);
  return results.every(result => result);
};

/**
 * Polls the provided images to check if all their variants are ready.
 * @param imageIds Array of image IDs to poll.
 * @param options Optional configuration, including variantKey.
 * @returns Promise that resolves when all images are ready or rejects if polling exceeds MAX_POLLS.
 */
export const areAllImagesLoaded = async (imageIds: string[], options?: Options): Promise<void> => {
  const { variantKey = ImageSize.ORIGINAL, type } = options || {};
  let attempts = 0;

  while (attempts < MAX_POLLS) {
    const allLoaded = await checkAllImagesLoaded(imageIds, variantKey, type);

    if (allLoaded) {
      return;
    }

    await sleep(POLL_INTERVAL);
    attempts += 1;
  }

  throw new Error('Image processing took too long.');
};
